import { handleActions, createAction } from 'redux-actions';
import _find from 'lodash/find';
import _first from 'lodash/first';
import { client as apollo } from 'cccisd-apollo';
import clientsQuery from './clientsQuery.graphql';
import clientQuery from './clientQuery.graphql';

// Initial state
export const initialState = {
    loading: true,
    clients: [],
    currentClient: {},
};

// Actions
const SET_LOADING_STATE = 'app/SET_LOADING_STATE';
const UNSET_LOADING_STATE = 'app/UNSET_LOADING_STATE';
const SET_CURRENT_CLIENT = 'app/SET_CURRENT_CLIENT';
const UNSET_CURRENT_CLIENT = 'app/UNSET_CURRENT_CLIENT';
const SET_CLIENTS = 'app/SET_CLIENTS';
const UNSET_CLIENTS = 'app/UNSET_CLIENTS';
const RESET_STATE = 'app/RESET_STATE';

// Action Creators
export const setLoadingState = createAction(SET_LOADING_STATE);
export const unsetLoadingState = createAction(UNSET_LOADING_STATE);
export const setCurrentClient = createAction(SET_CURRENT_CLIENT);
export const unsetCurrentClient = createAction(UNSET_CURRENT_CLIENT);
export const setClients = createAction(SET_CLIENTS);
export const unsetClients = createAction(UNSET_CLIENTS);
export const resetState = createAction(RESET_STATE);

const _getClients = async pawn => {
    let result = await apollo.query({
        query: clientsQuery,
        variables: {
            pawnId: pawn.id,
        },
        fetchPolicy: 'network-only',
    });

    return result.data.roles.clientList;
};

const _getClient = async client => {
    let result = await apollo.query({
        query: clientQuery,
        variables: {
            pawnId: client.pawn.pawnId,
        },
        fetchPolicy: 'network-only',
    });

    return _first(result.data.roles.clientList);
};

const _determineCurrentClient = (pawn, clients) => {
    if (localStorage.impact_currentClient) {
        let localStorageClient = JSON.parse(localStorage.getItem('impact_currentClient'));
        // local storage client not in client list anymore, maybe deleted in rostering
        if (!_find(clients, c => c.pawn.pawnId === localStorageClient.pawn.pawnId)) {
            localStorage.setItem('impact_currentClient', JSON.stringify(_first(clients)));
        }
        return JSON.parse(localStorage.getItem('impact_currentClient'));
    }

    localStorage.setItem('impact_currentClient', JSON.stringify(_first(clients)));

    return _first(clients);
};

export const buildClientData = ({ pawn = {}, clientId = undefined }) => {
    return async (dispatch, getState) => {
        dispatch(setLoadingState());
        const clients = await _getClients(pawn);
        dispatch(setClients(clients));
        if (clients.length > 0) {
            let client = {};
            if (clientId) {
                client = _find(clients, c => c.pawn.pawnId === parseInt(clientId, 10));
                localStorage.setItem('impact_currentClient', JSON.stringify(client));
            } else {
                client = _determineCurrentClient(pawn, clients);
            }

            const currentClient = await _getClient(client);
            dispatch(setCurrentClient(currentClient));
        }
        dispatch(unsetLoadingState());
    };
};

export const changeClient = client => {
    return async (dispatch, getState) => {
        dispatch(setLoadingState());
        const currentClient = await _getClient(client);
        dispatch(setCurrentClient(currentClient));
        localStorage.setItem('impact_currentClient', JSON.stringify(currentClient));
        dispatch(unsetLoadingState());
    };
};

// Reducers
export default handleActions(
    {
        [SET_LOADING_STATE]: (state, action) => ({
            ...state,
            loading: true,
        }),
        [UNSET_LOADING_STATE]: (state, action) => ({
            ...state,
            loading: false,
        }),
        [UNSET_CURRENT_CLIENT]: (state, action) => ({
            ...state,
            currentClient: {},
        }),
        [SET_CURRENT_CLIENT]: (state, action) => ({
            ...state,
            currentClient: action.payload,
        }),
        [UNSET_CLIENTS]: (state, action) => ({
            ...state,
            clients: [],
        }),
        [SET_CLIENTS]: (state, action) => ({
            ...state,
            clients: action.payload,
        }),
        [RESET_STATE]: (state, action) => ({
            loading: true,
            clients: [],
            currentClient: {},
        }),
    },
    initialState
);
