import {
    FidelityReportTemplate,
    LogReportTemplate,
    OutcomesGroupReportTemplate,
    OutcomesIndividualReportTemplate,
    OutcomesGroupComparisonReportTemplate,
} from 'cccisd-impact';
import attendanceTrackerQuery from './graphql/attendanceTracker.graphql';
import logCalloutsQuery from './graphql/logCallouts.graphql';
import logCommentsQuery from './graphql/logComments.graphql';
import outcomesGCSummaryCalloutsQuery from './graphql/outcomesGCSummaryCallouts.graphql';
import _round from 'lodash/round';
import _mean from 'lodash/mean';

export default [
    FidelityReportTemplate,
    OutcomesIndividualReportTemplate,
    {
        ...OutcomesGroupReportTemplate,
        widgetProps: {
            outcomesSummaryCallouts: {
                query: outcomesGCSummaryCalloutsQuery,
                convertDataStructure: data => {
                    let convertedData = {};

                    if (data && data.groups.treatmentGroupList.length > 0) {
                        const getTeenScores = () => {
                            let teenPreScores = [];
                            let teenPostScores = [];
                            data.groups.treatmentGroupList.forEach(group => {
                                const teenPre = group.childRoles.participantSummary.youthPre;
                                const teenPost = group.childRoles.participantSummary.youthPost;
                                if (teenPre !== null) {
                                    teenPreScores.push(Number(teenPre));
                                }
                                if (teenPost !== null) {
                                    teenPostScores.push(Number(teenPost));
                                }
                            });

                            const preScore = _round(_mean(teenPreScores), 1);
                            const postScore = _round(_mean(teenPostScores), 1);
                            return {
                                pre: Number.isNaN(preScore) ? '' : preScore,
                                post: Number.isNaN(postScore) ? '' : postScore,
                            };
                        };

                        const getCaregiverScores = () => {
                            let caregiverPreScores = [];
                            let caregiverPostScores = [];
                            data.groups.treatmentGroupList.forEach(group => {
                                const caregiverPre =
                                    group.childRoles.participantSummary.caregiverPre;
                                const caregiverPost =
                                    group.childRoles.participantSummary.caregiverPost;
                                if (caregiverPre !== null) {
                                    caregiverPreScores.push(Number(caregiverPre));
                                }
                                if (caregiverPost !== null) {
                                    caregiverPostScores.push(Number(caregiverPost));
                                }
                            });

                            const preScore = _round(_mean(caregiverPreScores), 1);
                            const postScore = _round(_mean(caregiverPostScores), 1);
                            return {
                                pre: Number.isNaN(preScore) ? '' : preScore,
                                post: Number.isNaN(postScore) ? '' : postScore,
                            };
                        };

                        convertedData = {
                            teenPre: getTeenScores().pre,
                            teenPost: getTeenScores().post,
                            caregiverPre: getCaregiverScores().pre,
                            caregiverPost: getCaregiverScores().post,
                        };
                    } else {
                        convertedData = {
                            teenPre: 65.25,
                            teenPost: 73.75,
                            caregiverPre: 70.0,
                            caregiverPost: 75.0,
                        };
                    }
                    // console.log({ convertedData });
                    return convertedData;
                },
            },
        },
    },

    {
        ...OutcomesGroupComparisonReportTemplate,
        widgetProps: {
            outcomesSummaryCallouts: {
                query: outcomesGCSummaryCalloutsQuery,
                convertDataStructure: data => {
                    let convertedData = {};

                    if (data && data.groups.treatmentGroupList.length > 0) {
                        const getTeenScores = () => {
                            let teenPreScores = [];
                            let teenPostScores = [];
                            data.groups.treatmentGroupList.forEach(group => {
                                const teenPre = group.childRoles.participantSummary.youthPre;
                                const teenPost = group.childRoles.participantSummary.youthPost;
                                if (teenPre !== null) {
                                    teenPreScores.push(Number(teenPre));
                                }
                                if (teenPost !== null) {
                                    teenPostScores.push(Number(teenPost));
                                }
                            });

                            const preScore = _round(_mean(teenPreScores), 1);
                            const postScore = _round(_mean(teenPostScores), 1);
                            return {
                                pre: Number.isNaN(preScore) ? '' : preScore,
                                post: Number.isNaN(postScore) ? '' : postScore,
                            };
                        };

                        const getCaregiverScores = () => {
                            let caregiverPreScores = [];
                            let caregiverPostScores = [];
                            data.groups.treatmentGroupList.forEach(group => {
                                const caregiverPre =
                                    group.childRoles.participantSummary.caregiverPre;
                                const caregiverPost =
                                    group.childRoles.participantSummary.caregiverPost;
                                if (caregiverPre !== null) {
                                    caregiverPreScores.push(Number(caregiverPre));
                                }
                                if (caregiverPost !== null) {
                                    caregiverPostScores.push(Number(caregiverPost));
                                }
                            });

                            const preScore = _round(_mean(caregiverPreScores), 1);
                            const postScore = _round(_mean(caregiverPostScores), 1);
                            return {
                                pre: Number.isNaN(preScore) ? '' : preScore,
                                post: Number.isNaN(postScore) ? '' : postScore,
                            };
                        };

                        convertedData = {
                            teenPre: getTeenScores().pre,
                            teenPost: getTeenScores().post,
                            caregiverPre: getCaregiverScores().pre,
                            caregiverPost: getCaregiverScores().post,
                        };
                    } else {
                        convertedData = {
                            teenPre: 65.25,
                            teenPost: 73.75,
                            caregiverPre: 70.0,
                            caregiverPost: 75.0,
                        };
                    }
                    // console.log({ convertedData });
                    return convertedData;
                },
            },
        },
    },
    {
        ...LogReportTemplate,
        widgetProps: {
            logAttendanceTracker: {
                query: attendanceTrackerQuery,
                convertDataStructure: data => {
                    const getSessionsAttended = (sessionData, node) => {
                        let sessions = [];
                        sessionData.forEach(session => {
                            if (session.devTags[node] === '1') {
                                sessions.push(
                                    Number(
                                        session.deployment.label[
                                            session.deployment.label.length - 1
                                        ]
                                    )
                                );
                            }
                        });
                        return sessions;
                    };

                    let convertedData = [];
                    if (data.length > 0) {
                        convertedData = data.map(group => {
                            if (
                                group['group.groupId'] &&
                                group['childRoles.participantList'].length > 0
                            ) {
                                return {
                                    groupId: group['group.groupId'],
                                    groupName: group['group.label'],
                                    sessionsCompleted: 5,
                                    participants: group['childRoles.participantList'].map(
                                        participant => {
                                            return {
                                                id: participant.pawn.pawnId,
                                                name: participant.fields.participantId,
                                                attended: getSessionsAttended(
                                                    participant.assignmentProgressList,
                                                    'YouthSessionAttendance'
                                                ),
                                                caregiverAttended: getSessionsAttended(
                                                    participant.assignmentProgressList,
                                                    'CaregiverSessionAttendance'
                                                ),
                                                youthAttendanceRate:
                                                    participant.assignmentProgressSummary
                                                        .youthAttAvg,
                                                caregiverAttendanceRate:
                                                    participant.assignmentProgressSummary
                                                        .caregiverAttAvg,
                                            };
                                        }
                                    ),
                                };
                            }
                            return null;
                        });
                    } else {
                        convertedData = [
                            {
                                groupId: 0,
                                groupName: 'Group1',
                                sessionsCompleted: 5,
                                participants: [
                                    {
                                        id: 1,
                                        name: 'MB0101',
                                        attended: [1, 2, 3, 4, 5],
                                        caregiverAttended: [1, 2, 3, 4, 5],
                                        youthAttendanceRate: 1,
                                        caregiverAttendanceRate: 1,
                                    },
                                    {
                                        id: 2,
                                        name: 'MB0102',
                                        attended: [1, 2, 3, 4, 5],
                                        caregiverAttended: [1, 2, 3, 4],
                                        youthAttendanceRate: 1,
                                        caregiverAttendanceRate: 0.8,
                                    },
                                    {
                                        id: 3,
                                        name: 'MB0103',
                                        attended: [1, 2, 4, 5],
                                        caregiverAttended: [1, 2, 4, 5],
                                        youthAttendanceRate: 1,
                                        caregiverAttendanceRate: 1,
                                    },
                                ],
                            },
                            {
                                groupId: 1,
                                groupName: 'Group2',
                                sessionsCompleted: 5,
                                participants: [
                                    {
                                        id: 4,
                                        name: 'MB0104',
                                        attended: [1, 2, 3, 4, 5],
                                        caregiverAttended: [1, 2, 3, 4, 5],
                                        youthAttendanceRate: 1,
                                        caregiverAttendanceRate: 1,
                                    },
                                    {
                                        id: 5,
                                        name: 'MB0105',
                                        attended: [1, 2, 3, 4, 5],
                                        caregiverAttended: [1, 2, 3, 4],
                                        youthAttendanceRate: 1,
                                        caregiverAttendanceRate: 0.8,
                                    },
                                    {
                                        id: 6,
                                        name: 'MB0106',
                                        attended: [1, 2, 4, 5],
                                        caregiverAttended: [1, 2, 4, 5],
                                        youthAttendanceRate: 1,
                                        caregiverAttendanceRate: 1,
                                    },
                                ],
                            },
                        ];
                    }
                    // console.log({ convertedData });
                    const filteredData = convertedData
                        .filter(d => d !== null)
                        .filter(i => i.participants.length > 0);

                    // console.log({ filteredData });
                    return filteredData;
                },
            },
            logSummaryCallouts: {
                query: logCalloutsQuery,
                convertDataStructure: data => {
                    // console.log(data);
                    let convertedData = {};
                    if (data && data.groups.treatmentGroupList.length > 0) {
                        const getAttendanceRate = node => {
                            let attendanceAllGroups = [];
                            data.groups.treatmentGroupList.forEach(tg => {
                                let val;
                                tg.childRoles.participantList.forEach(pl => {
                                    val = pl.assignmentProgressSummary[node[0]];
                                    if (typeof val !== 'object') {
                                        attendanceAllGroups.push(val * 100);
                                    }
                                });
                            });

                            let averageAttendance;
                            // console.log(attendanceAllGroups);
                            if (attendanceAllGroups.length > 0) {
                                if (attendanceAllGroups.length > 1) {
                                    averageAttendance =
                                        attendanceAllGroups.reduce((a, b) => a + b) /
                                        attendanceAllGroups.length;
                                } else {
                                    return _round(attendanceAllGroups[0]);
                                }
                            } else {
                                return '';
                            }
                            return _round(averageAttendance);
                        };

                        const getTeensAttendedPct = () => {
                            let numTeensAttendedThreePlusSessions = 0;
                            let totalTeens = 0;

                            data.groups.treatmentGroupList.forEach(tg => {
                                tg.childRoles.participantList.forEach(participant => {
                                    const didAttend = participant.assignmentProgressSummary.frequency.find(
                                        f => f.value === '1'
                                    );
                                    const didNotAttend = participant.assignmentProgressSummary.frequency.find(
                                        f => f.value === '0'
                                    );
                                    if (didAttend && didNotAttend) {
                                        if (
                                            didAttend.valueCount >= 3 ||
                                            didNotAttend.valueCount >= 3
                                        ) {
                                            if (didAttend.valueCount >= 3) {
                                                numTeensAttendedThreePlusSessions++;
                                            }
                                            totalTeens++;
                                        } else if (
                                            didAttend.valueCount + didNotAttend.valueCount >=
                                            3
                                        ) {
                                            if (didAttend.valueCount >= 3) {
                                                numTeensAttendedThreePlusSessions++;
                                            }
                                            totalTeens++;
                                        }
                                    } else if (didAttend) {
                                        if (didAttend.valueCount >= 3) {
                                            numTeensAttendedThreePlusSessions++;
                                        }
                                        totalTeens++;
                                    } else if (didNotAttend) {
                                        if (didNotAttend.valueCount >= 3) {
                                            totalTeens++;
                                        }
                                    } else {
                                        totalTeens++;
                                    }
                                });
                            });

                            // console.log({ numTeensAttendedThreePlusSessions, totalTeens });
                            // console.log(
                            //     _round((numTeensAttendedThreePlusSessions / totalTeens) * 100)
                            // );
                            return _round((numTeensAttendedThreePlusSessions / totalTeens) * 100);
                        };

                        convertedData = {
                            teenAttendance: getAttendanceRate(['youthAttAvg']),
                            targetAttendance: 80,
                            teensAttendedPct: getTeensAttendedPct(),
                            targetTeensPct: 80,
                            caregiverAttendance: getAttendanceRate(['caregiverAttAvg']),
                            targetCaregiverAttendance: 80,
                        };
                    } else {
                        convertedData = {
                            teenAttendance: 60,
                            targetAttendance: 80,
                            teensAttendedPct: 70,
                            targetTeensPct: 80,
                            caregiverAttendance: 85,
                            targetCaregiverAttendance: 80,
                        };
                    }
                    // console.log({ convertedData });
                    return convertedData;
                },
            },
            logComments: {
                query: logCommentsQuery,
                convertDataStructure: data => {
                    let convertedData = [];
                    if (data.length > 0) {
                        convertedData = data.map(group => {
                            return {
                                id: group['group.groupId'],
                                groupName: group['group.label'],
                                sessionData: [
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 1,
                                        sessionDate:
                                            group['childRoles.metricspawn.session1.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.YouthAttendanceLogComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 2,
                                        sessionDate:
                                            group['childRoles.metricspawn.session2.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.YouthAttendanceLogComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 3,
                                        sessionDate:
                                            group['childRoles.metricspawn.session3.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.YouthAttendanceLogComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 4,
                                        sessionDate:
                                            group['childRoles.metricspawn.session4.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.YouthAttendanceLogComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 5,
                                        sessionDate:
                                            group['childRoles.metricspawn.session5.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.YouthAttendanceLogComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                ],
                            };
                        });
                    } else {
                        convertedData = [
                            {
                                id: 10,
                                groupName: 'My Group',
                                sessionData: [
                                    {
                                        id: 10,
                                        groupName: 'My Group',
                                        session: 1,
                                        sessionDate: '2020-10-01',
                                        teenComment: 'Teen Comment Session 1',
                                        caregiverComment: 'Caregiver comment session 1',
                                    },
                                    {
                                        id: 10,
                                        groupName: 'My Group',
                                        session: 2,
                                        sessionDate: '2020-10-08',
                                        teenComment: 'teen comment session 2',
                                        caregiverComment: 'caregiver comment session 2',
                                    },
                                ],
                            },
                        ];
                    }
                    return convertedData;
                },
            },
        },
    },
];
