import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'cccisd-laravel-boilerplate';
import style from './style.css';

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        let showTheseProfileDataFields = {
            groupingAdmin: ['idNumber'],
            manager: ['idNumber'],
            siteAdmin: ['idNumber'],
        };

        return (
            <NavBar
                className={this.props.className}
                showTheseProfileDataFields={showTheseProfileDataFields}
                logo={
                    <div>
                        <div className={style.logo} />
                        <div className={style.logoTitle}>Strong African American Families Teen Program</div>
                    </div>
                }
            />
        );
    }
}
